/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */

import React from 'react';
import createClass from 'create-react-class';
import PropTypes from 'prop-types';
import scriptjs from 'scriptjs';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { darken, lighten, fade } from 'material-ui/utils/colorManipulator';

import moment from 'moment';

import store from './store';
import RealBookingApp from './config/BookingApp';

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    const nextRootReducer = require('./reducers').default; // eslint-disable-line global-require
    store.replaceReducer(nextRootReducer);
  });
}

moment.locale('en-ZA', {
  parentLocale: 'en',
  longDateFormat: {
    L: 'YYYY/MM/DD',
  },
});

const bianca = '#FEFEFC';
const silver = '#CCCCCC';
const mineShaft = '#303030';
const mojo = '#C73D41';
const pickledBluewood = '#2B3D4F';

const white = bianca;
const blue = pickledBluewood;
const red = mojo;
const black = mineShaft;
const darkGray = darken(silver, 0.3);
const gray = silver;
const lightGray = lighten(silver, 0.15);

export const wrapIBE = RealComponent =>
  class IBEWrapper extends React.Component {
    // eslint-disable-line react/prefer-stateless-function
    static propTypes = {
      primaryColor: PropTypes.string,
      secondaryColor: PropTypes.string,
      language: PropTypes.string.isRequired,
      ccvURL: PropTypes.string.isRequired,
      setOnSearchStarted: PropTypes.func,
    };

    static defaultProps = {
      setOnSearchStarted: () => {},
      primaryColor: blue,
      secondaryColor: red,
    };

    constructor(props) {
      super(props);
      if (props.ccvURL) {
        // we are passing in react and createClass here as CCV components
        // rely on them to be present in window
        window.React = React;
        window.createReactClass = createClass;
        scriptjs(`${props.ccvURL}/assets/v5-welldev-credit-card-actions.js`);
      }
    }

    render() {
      const muiTheme = getMuiTheme({
        fontFamily: 'Roboto, sans-serif',
        palette: {
          primary1Color: this.props.primaryColor,
          primary2Color: darken(this.props.primaryColor, 0.3),
          primary3Color: darkGray,
          secondaryTextColor: lightGray,
          alternateTextColor: white,
          borderColor: gray,
          canvasColor: white,
          disabledColor: gray,
          accent1Color: this.props.secondaryColor,
          accent2Color: lightGray,
          accent3Color: darkGray,
          textColor: black,
          pickerHeaderColor: this.props.primaryColor,
          clockCircleColor: fade(black, 0.07),
          shadowColor: black,
        },
        tabs: {
          backgroundColor: white,
          selectedTextColor: black,
          textColor: darkGray,
        },
        toolbar: {
          backgroundColor: white,
        },
        menuItem: {
          secondaryText: { color: lightGray },
        },
      });

      moment.locale(this.props.language);

      return (
        <MuiThemeProvider muiTheme={muiTheme}>
          <RealComponent store={store} {...this.props} />
        </MuiThemeProvider>
      );
    }
  };

const BookingApp = wrapIBE(RealBookingApp);
export default BookingApp;
